<template>
    <div>
        <nav class="navbar fixed-top navbar-dark bg-dark">
            <div class="container">
                <div class="col-12">
                    <div class="form-group row">
                        <label for="selSeason" class="col-sm-2 col-form-label text-white">Select Season: </label>
                        <div class="col-sm-10">
                            <div class="row">
                                <div class="col-10">
                                    <select class="form-select" name="selSeason" id="selSeason" v-model="currentSeason.year" @change="setSeason($event.target.selectedIndex)">
                                        <option :value="season.year" :key="season.id" v-for="season in seasons">{{season.year}}</option>
                                    </select>                            
                                </div>
                                <div class="col-2">
                                    <a href="/livetv/" class="btn btn-outline-danger">Live</a>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        <TopBanner />
        <div class="container mt-4">
            <div class="text-center" v-for="(round, index) in currentMgp" :key="round.round">
                <b-card>
                    <b-card-text>
                        <h1>Round: {{round.round}}</h1>
                        <h2>{{round.title}}</h2>
                        <p>Date: {{round.date}}</p>
                        <p>Circuit: {{round.circuit}}</p>
                        <div class="row">
                            <div class="col-sm" v-if="round.motoe.length > 0">
                                <h3>MotoE</h3>
                                <div class="col-12 mb-3" block variant="primary">
                                    <button class="btn btn-outline-danger" @click="storeRound(index, round.motoe, 'motoe')">Watch Now</button>
                                </div>  
                            </div>
                            <div class="col-sm" v-if="round.moto3.length > 0">
                                <h3>Moto3</h3>
                                <div class="col-12 mb-3" block variant="primary">
                                    <button class="btn btn-outline-danger" @click="storeRound(index, round.moto3, 'moto3')">Watch Now</button>
                                </div> 
                            </div>
                            <div class="col-sm" v-if="round.moto2.length > 0">
                                <h3>Moto2</h3>
                                <div class="col-12 mb-3" block variant="primary">
                                    <button class="btn btn-outline-danger" @click="storeRound(index, round.moto2, 'moto2')">Watch Now</button>
                                </div> 
                            </div>
                            <div class="col-sm" v-if="round.motogp.length > 0">
                                <h3>MotoGP</h3>
                                <div class="col-12 mb-3" block variant="primary">
                                    <button class="btn btn-outline-danger" @click="storeRound(index, round.motogp, 'motogp')">Watch Now</button>
                                </div> 
                            </div>
                        </div>
                    </b-card-text>
                </b-card>
                <br>
            </div>
    
            
        </div>
        <BottomBanner />
        <div class="container mt-2">
            <DisclaimerBottom />
        </div>
        <FooterCom />
    </div>
</template>
<script>
    import seasonData from '../assets/season.json'
    import TopBanner from './TopBanner.vue'
    import BottomBanner from './BottomBanner.vue'
    import FooterCom from './FooterCom.vue'
    import DisclaimerBottom from './DisclaimerBottom.vue'
    
    export default {
        name: 'HomePage',
        data() {
            return {
                seasons: seasonData,
                currentMgp: [],
                currentSeason: {
                    id: 33,
                    year: "2024"
                },
                vidurl: 'https://short.ink/'
            }
        },
        components: {
            TopBanner,
            BottomBanner,
            FooterCom,
            DisclaimerBottom
        },
        methods: {
            setSeason: function (selectedIndex) {
                var selectedSeason = this.seasons[selectedIndex];
                this.currentSeason = {
                    id: selectedSeason.id,
                    year: selectedSeason.year
                };
                localStorage.setItem('seasonYear', selectedSeason.year);
                let datssss = this.$SeasonSelector.SelSeason(selectedSeason.year)
                this.currentMgp = datssss.currentMgp
                this.currentSeason = datssss.currentSeason

            },
            storeRound(idx, data= [], gpType){
                let slug = gpType + '-' + this.currentSeason.year + '-round-' + (idx + 1)
                this.$router.push({
                    name: 'watch',
                    params: {
                        id: slug
                    }
                })
            },
        },
        created: function() {
            try {

                let theYear = localStorage.getItem('seasonYear')
                if(theYear){
                    this.currentMgp.year = theYear
                }else{
                    this.currentMgp.year = '2023'
                }

                let datssss = this.$SeasonSelector.SelSeason(theYear)
                this.currentMgp = datssss.currentMgp
                this.currentSeason = datssss.currentSeason
                
            } catch (error) {
                this.currentMgp.year = '2023'
            }
        }
    }
</script>
<style>
    .mt-5 {
        margin-top: 4rem !important;
    }
</style>