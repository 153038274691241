<template>
    <p class="text-white">
        <strong>DISCLAIMER:</strong> This content is provided and hosted by a 3rd party server. Sometimes these servers may include advertisements. MotoGPRewind does not host or upload this material and is not responsible for the content.
    </p>
</template>
<script>
export default {
    name: 'DisclaimerBottom',
    data() {
        return {}
    }
}
</script>
<style>
    
</style>