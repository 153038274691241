<template>
    <div class="container mt-1">
        <!--<a href="https://bit.ly/3ROqlCV" target="_blank">
            <img class="img-fluid img-100" src="https://res.cloudinary.com/drckwuxin/image/upload/v1696312101/terabox_jkpe5z.gif" alt="terabox" width="900" height="250" />
        </a>-->
    </div>
</template>
<script>
export default {
    name: 'BottomBanner',
        data() {
            return {}
        }
}
</script>
<style>
    
</style>