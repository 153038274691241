<template>
    <footer>
        <cookie-law theme="dark-lime"></cookie-law>
    </footer>
</template>
<script>
    import CookieLaw from 'vue-cookie-law'
    export default {
        components: { CookieLaw }
    }
</script>
<style>
    
</style>