import s2024 from '../assets/motogp/2024.json'
import s2023 from '../assets/motogp/2023.json'
import s2022 from '../assets/motogp/2022.json'
import s2021 from '../assets/motogp/2021.json'
import s2020 from '../assets/motogp/2020.json'
import s2019 from '../assets/motogp/2019.json'
import s2018 from '../assets/motogp/2018.json'
import s2017 from '../assets/motogp/2017.json'
import s2016 from '../assets/motogp/2016.json'
import s2015 from '../assets/motogp/2015.json'
import s2014 from '../assets/motogp/2014.json'
import s2013 from '../assets/motogp/2013.json'
import s2012 from '../assets/motogp/2012.json'
import s2011 from '../assets/motogp/2011.json'
import s2010 from '../assets/motogp/2010.json'
import s2009 from '../assets/motogp/2009.json'
import s2008 from '../assets/motogp/2008.json'
import s2007 from '../assets/motogp/2007.json'
import s2006 from '../assets/motogp/2006.json'
import s2005 from '../assets/motogp/2005.json'
import s2004 from '../assets/motogp/2004.json'
import s2003 from '../assets/motogp/2003.json'
import s2002 from '../assets/motogp/2002.json'
import s2001 from '../assets/motogp/2001.json'
import s2000 from '../assets/motogp/2000.json'
import s1999 from '../assets/motogp/1999.json'
import s1998 from '../assets/motogp/1998.json'
import s1997 from '../assets/motogp/1997.json'
import s1996 from '../assets/motogp/1996.json'
import s1995 from '../assets/motogp/1995.json'
import s1994 from '../assets/motogp/1994.json'
import s1993 from '../assets/motogp/1993.json'
import s1992 from '../assets/motogp/1992.json'

export const SeasonSelector = {
    SelSeason: function (year) {
        
        let currentMgp = []
        let currentSeason = []

        switch (year) {
            case "2024":
                currentMgp = s2024.items;
                currentSeason = {id: 33, year: 2024}
                break;
            case "2023":
                currentMgp = s2023.items;
                currentSeason = {id: 32, year: 2023}
                break;
            case "2022":
                currentMgp = s2022.items;
                currentSeason = {id: 31, year: 2022}
                break;
            case "2021":
                currentMgp = s2021.items;
                currentSeason = {id: 30, year: 2021}
                break;
            case "2020":
                currentMgp = s2020.items;
                currentSeason = {id: 29, year: 2020}
                break;
            case "2019":
                currentMgp = s2019.items;
                currentSeason = {id: 28, year: 2019}
                break;
            case "2018":
                currentMgp = s2018.items;
                currentSeason = {id: 27, year: 2018}                
                break;
            case "2017":
                currentMgp = s2017.items;
                currentSeason = {id: 26, year: 2017}
                break;
            case "2016":
                currentMgp = s2016.items;
                currentSeason = {id: 25, year: 2016}
                break;
            case "2015":
                currentMgp = s2015.items;
                currentSeason = {id: 24, year: 2015}
                break;
            case "2014":
                currentMgp = s2014.items;
                currentSeason = {id: 23, year: 2014}
                break;
            case "2013":
                currentMgp = s2013.items;
                currentSeason = {id: 22, year: 2013}
                break;
            case "2012":
                currentMgp = s2012.items;
                currentSeason = {id: 21, year: 2012}
                break;
            case "2011":
                currentMgp = s2011.items;
                currentSeason = {id: 20, year: 2011}
                break;
            case "2010":
                currentMgp = s2010.items;
                currentSeason = {id: 19, year: 2010}
                break;
            case "2009":
                currentMgp = s2009.items;
                currentSeason = {id: 18, year: 2009}
                break;
            case "2008":
                currentMgp = s2008.items;
                currentSeason = {id: 17, year: 2008}
                break;
            case "2007":
                currentMgp = s2007.items;
                currentSeason = {id: 16, year: 2007}
                break;
            case "2006":
                currentMgp = s2006.items;
                currentSeason = {id: 15, year: 2006}
                break;
            case "2005":
                currentMgp = s2005.items;
                currentSeason = {id: 14, year: 2005}
                break;
            case "2004":
                currentMgp = s2004.items;
                currentSeason = {id: 13, year: 2004}
                break;
            case "2003":
                currentMgp = s2003.items;
                currentSeason = {id: 12, year: 2003}
                break;
            case "2002":
                currentMgp = s2002.items;
                currentSeason = {id: 11, year: 2002}
                break;
            case "2001":
                currentMgp = s2001.items;
                currentSeason = {id: 10, year: 2001}
                break;
            case "2000":
                currentMgp = s2000.items;
                currentSeason = {id: 9, year: 2000}
                break;
            case "1999":
                currentMgp = s1999.items;
                currentSeason = {id: 8, year: 1999}
                break;
            case "1998":
                currentMgp = s1998.items;
                currentSeason = {id: 7, year: 1998}
                break;
            case "1997":
                currentMgp = s1997.items;
                currentSeason = {id: 6, year: 1997}
                break;
            case "1996":
                currentMgp = s1996.items;
                currentSeason = {id: 5, year: 1996}
                break;
            case "1995":
                currentMgp = s1995.items;
                currentSeason = {id: 4, year: 1995}
                break;
            case "1994":
                currentMgp = s1994.items;
                currentSeason = {id: 3, year: 1994}
                break;
            case "1993":
                currentMgp = s1993.items;
                currentSeason = {id: 2, year: 1993}
                break;
            case "1992":
                currentMgp = s1992.items;
                currentSeason = {id: 1, year: 1992}
                break;
            default:
                currentMgp = s2023.items;
                currentSeason = {id: 32, year: 2023}
        }

        return {currentMgp: currentMgp, currentSeason: currentSeason}
    }
}