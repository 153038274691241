<template>
    <div>
        <h2>Click Button to start watch!</h2>
    </div>
</template>
<script>
export default {
    name: 'SelectorView',
}
</script>
<style>
    
</style>