import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SelectorView from '../views/SelectorView.vue'
// import WatchView from '../views/WatchView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    // path: '/watch',
    path: '/watch/:id',
    name: 'watch',
    component: () => import(/* webpackChunkName: "watch" */ '../views/WatchView.vue'),
    props: true
    //component: WatchView
  },
  {
    // path: '/watch',
    path: '/livetv/',
    name: 'live',
    component: () => import(/* webpackChunkName: "watch" */ '../views/LiveView.vue'),
    props: true
    //component: LiveView
  },
  {
    path: '/selector',
    name: 'selector',
    component: SelectorView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
